<template>
  <q-table
    style="height: 100%;"
    class="sticky-header-table"
    row-key="id"
    :rows-per-page-label="$t('Rows per page')"
    :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
    :rows="history"
    :columns="columns"
    v-model:pagination="pagination"
    :loading="historyLoading"
    :filter="filter"
    virtual-scroll
    binary-state-sort
    flat
    @request="onRequest"
  >
    <template v-slot:body="props">
      <q-tr
        :props="props"
        class="clickable"
      >
        <q-td
          key="id"
          :props="props"
          class="text-subtitle1"
        >
          {{ props.row.id }}
        </q-td>

        <q-td
          key="action"
          :props="props"
        >
          {{ props.row.action }}
        </q-td>

        <q-td
          key="username"
          :props="props"
        >
          {{ props.row.username }}
        </q-td>

        <q-td
          key="data"
          :props="props"
          style="max-width: 320px; overflow: auto;"
        >
          <history-collapse :data="props.row.data" />
        </q-td>

        <q-td
          key="loggedAt"
          :props="props"
        >
          {{ $moment(props.row.loggedAt.date).format('DD-MM-YYYY, h:mm:ss') }}
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>
<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'

// Utils
import { buildQuery } from '../../utils/query-utils'

// Mixins
import TableMixin from './../global/TableMixin'

// Components
import HistoryCollapse from './../history-collapse/HistoryCollapse'

export default {
  name: 'History',
  components: {
    HistoryCollapse
  },
  mixins: [TableMixin],
  props: {
    entity: {
      type: String,
      default () {
        return 'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest'
      }
    },
    id: {
      type: Number || String,
      default () {
        return 0
      }
    }
  },
  data () {
    return {
      filter: '',
      pagination: {
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: this.$t('ID'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Action'),
          name: 'action',
          align: 'left'
        },
        {
          label: this.$t('User'),
          name: 'username',
          align: 'left'
        },
        {
          label: this.$t('Data'),
          name: 'data',
          align: 'left'
        },
        {
          label: this.$t('Logged At'),
          name: 'loggedAt',
          align: 'left'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'history',
      'historyItemsNumber',
      'historyLoading'
    ])
  },
  mounted () {
    this.loadDefaultItems()
  },
  methods: {
    ...mapActions([
      'loadRequestHistory'
    ]),
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      query.entity = this.entity

      return this.loadRequestHistory(query)
        .then(({ page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }
        })
    },
    loadDefaultItems () {
      const pagination = {
        per_page: 25,
        page: 1,
        id: this.id,
        'order-by': [
          { type: 'field', field: 'id', direction: 'desc' }
        ]
      }

      return this.onRequest({ pagination })
    }
  }
}
</script>
