<template>
  <div
    class="row items-start justify-between full-width"
  >
    <div class="col">
      <object-code :data="data" />
    </div>

  </div>
</template>

<script>
export default {
  name: 'HistoryCollapse',
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    findMoreData (data) {
      const keys = Object.keys(data || {})

      if (keys.length >= 2) {
        return true
      }

      return keys.find(key => {
        return this.findMoreData(data[key])
      })
    },
  }
}
</script>

<style>
.body--dark .custom-json {
  color: #fff !important;
}

.body--dark .custom-json * {
  color: #fff !important;
}

.custom-json {
  background: transparent;
  color: #000 !important;
}

.custom-json * {
  color: #000 !important;
}
</style>
