<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 40vw;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          <q-icon name="settings" />

          {{ $t('Wave settings') }}
        </div>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="close"
        />
      </q-card-section>

      <q-card-section class="text-center q-pt-none">
        <queue-settings
          :settings="settings"
          :queue="model"
          :disabled="options.noSave"
          @change="updateModel"
        />
      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
          v-if="!options.noSave"
          color="light-blue-9"
          text-color="white"
          class="q-mr-sm"
          :label="$t('Save')"
          @click="save"
        />

        <q-btn
          color="dark"
          text-color="white"
          :label="$t('Close')"
          @click="close"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Components
import QueueSettings from '../queue/QueueSettings.vue'

// Lodash
import _ from 'lodash'

const defaultSettings = {
  'picking-endpoint-place-type': 'distribution',
  'wave-fill-type': 'automatic',
  'wave-state': 'preparing',
  'wave-order-limit': 1
}

Object.freeze(defaultSettings)

export default {
  name: 'SequenceSettingsModal',
  emits: ['submit'],
  components: {
    QueueSettings
  },
  data () {
    return {
      isOpen: false,
      service: null,
      model: null,
      settings: [],
      options: {},
      slide: 'type'
    }
  },
  methods: {
    updateModel (model) {
      this.model = model
    },
    close () {
      this.isOpen = false
    },
    open (model, service, options = {}) {
      this.options = options
      this.slide = options.waveSettings && options.isNew
        ? 'type'
        : 'settings'
      this.model = _.cloneDeep(model)
      this.settings = options.settings || []
      this.service = service
      this.isOpen = true
    },
    save () {
      return this.service.saveFilters(this.model.settings, this.model.id)
        .then(model => {
          this.$emit('submit', model)
        })
    }
  }
}
</script>
