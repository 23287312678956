<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 80vw;">
      <q-card-section class="row">
        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="close"
        />
      </q-card-section>

      <q-card-section class="q-pt-none">
        <div class="text-center q-px-lg q-pb-sm">
          <q-btn-dropdown
            color="light-blue-9"
            :label="$t(`Please set the filters to get storage items for ${type}.`)"
            no-caps
          >
            <q-list>
              <q-item
                v-for="filter in filters"
                :key="filter.id"
                v-close-popup
                clickable
                class="q-pa-none"
                @click="addFilter(filter.id)"
              >
                <q-item-section
                  class="q-pa-sm"
                  :class="model.settings.filters[filter.id] !== undefined ? 'hover-negative' : 'hover-positive'"
                >
                  <div class="row items-center">
                    <div
                      class="q-mr-sm text-h6"
                      :class="model.settings.filters[filter.id] !== undefined ? 'text-negative' : 'text-positive'"
                    >
                        {{ model.settings.filters[filter.id] !== undefined ? '-' : '+' }}
                    </div>

                    <div>{{ $t(filter.name) }}</div>
                  </div>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </div>

        <div
          v-if="Object.keys(model.settings.filters || {}).length <= 0"
          class="text-subtitle1 q-mt-md"
        >
            {{ $t('You can add filters. They determine for which storage items tasks will be created.') }}
        </div>

        <form-builder :schema="schema" />
      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
          class="q-mr-sm"
          color="light-blue-9"
          text-color="white"
          :label="$t('Confirm filter')"
          @click="save"
        />

        <q-btn
          color="dark"
          text-color="white"
          :label="$t('Close')"
          @click="close"
        />
      </q-card-section>

      <q-card-section class="text-center q-pt-none">
        <q-table
          class="sticky-header-table"
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="items"
          :columns="columns"
          v-model:pagination="pagination"
          :loading="isLoading"
          :filter="filter"
          :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
              showing
              color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
              :props="props"
              class="clickable"
            >
              <q-td
                key="image"
                :props="props"
                auto-width
              >
                <img
                  :src="props.row.image ? props.row.image : fallbackImage"
                  style="width: 80px; height: 70px; object-fit: contain;"
                  @error="onImageLoadFailure"
                >

              </q-td>

              <q-td
                key="id"
                :props="props"
                style="max-width: 120px; word-wrap: break-word; white-space: normal !important;"
              >
                <div class="text-subtitle1">
                    {{ `${props.row.name} (${props.row.id})` }}
                </div>

                <div class="text-caption">{{ props.row.article }}</div>
              </q-td>

              <q-td
                key="state"
                :props="props"
                auto-width
              >
                <q-badge
                  :color="stateColors[props.row.state]"
                  text-color="dark"
                  class="q-pa-sm"
                >
                  {{ $t(stateName[props.row.state] || props.row.state) }}
                </q-badge>
              </q-td>

              <q-td
                key="reserve"
                :props="props"
                auto-width
              >
                <div v-if="props.row._embedded && props.row._embedded.reserve">
                    {{ props.row._embedded.reserve.id }}
                </div>
              </q-td>

              <q-td
                key="shop"
                :props="props"
                auto-width
              >
                  <div v-if="props.row._embedded && props.row._embedded.shop">
                      {{ props.row._embedded.shop.name }}
                  </div>
              </q-td>

              <q-td
                key="price"
                :props="props"
                auto-width
              >
                <strong v-if="!props.row.price">
                  -
                </strong>

                <strong v-else>
                  {{ props.row.price }}
                </strong>
              </q-td>

              <q-td
                key="count"
                :props="props"
                auto-width
              >
                  {{ props.row.count }}
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'

// Lodash
import _ from 'lodash'

// Components

// Utils
import { buildQuery } from '../../utils/query-utils'

export default {
  name: 'QueueSettingsModalLocation',
  emits: ['submit'],
  components: {
  },
  data () {
    return {
      type: '',
      fallbackImage: 'assets/img/fallback-image/package.png',
      isOpen: false,
      service: null,
      model: { settings: { filters: {} } },
      settings: [],
      items: [],
      isLoading: false,
      filter: '',
      ifShop: false,
      shopFilterRow: null,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      stateName: {
        normal: 'On hand',
        booked: 'Booked',
        blocked: 'Blocked',
        new: 'Received'
      },
      stateColors: {
        new: 'grey-5',
        blocked: 'danger',
        booked: 'danger',
        deleted: 'danger',
        shipped: 'green',
        normal: 'success',
        expected: 'green',
        missing: 'grey'
      },
      columns: [
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'left'
        },
        {
          label: this.$t('Details'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: this.$t('Reserve'),
          name: 'reserve',
          align: 'left'
        },
        {
          label: this.$t('Store'),
          name: 'shop',
          align: 'left'
        },
        {
          label: this.$t('Price'),
          name: 'price',
          align: 'left'
        },
        {
          label: this.$t('Quantity'),
          name: 'count',
          align: 'center'
        }
      ],
      fieldsByKey: {
        createdByDocument: {
          type: 'input',
          label: 'Created By Document',
          inputType: 'text',
        },
        sku: {
          type: 'input',
          label: 'SKU',
          inputType: 'text',
        },
        state: {
          type: 'tag',
          options: [
            { id: 'normal', name: this.$t('Normal') },
            { id: 'blocked', name: this.$t('Blocked') },
            { id: 'booked', name: this.$t('Booked') },
            { id: 'returned', name: this.$t('Returned') },
            { id: 'defected', name: this.$t('Defected') }
          ],
          label: 'States'
        },
        shop: {
          type: 'tag',
          label: 'Stores',
          service: this.$service.shop,
          values: {}
        },
        warehouse: {
          type: 'tag',
          label: 'Warehouse',
          service: this.$service.warehouse,
          values: {}
        },
        id: {
          type: 'tag',
          label: 'Location',
          service: this.$service.storagePlace,
          value: {}
        },
        productOffer: {
          type: 'input',
          label: 'Product offer',
          inputType: 'text',
        },
      },
      fields: []
    }
  },
  computed: {
    ...mapGetters([
    ]),
    filters () {
      return this.getFilters()
    },
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: this.fields
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions([
      'loadPickingQueueRawSettings',
      'loadAssemblingQueueRawSettings'
    ]),
    handleShowInfo (item) {
      this.$refs.offerModal.open({ ...item._embedded.productOffer, _embedded: { shop: item._embedded.shop } })
    },
    onImageLoadFailure (e) {
      e.target.src = this.fallbackImage
    },
    onRequest (data = {}) {
        this.isLoading = true
        this.pagination = data.pagination || {}
        const query = buildQuery(this.pagination)

        query.search = this.search

        if (!query.filter) {
          query.filter = []
        }

        if (!query.per_page) {
          query.per_page = 25
        }

        if (this.place && !query.filter.find(x => x.field === 'place')) {
          query.filter.push({ type: 'eq', field: 'place', value: this.place.id })
        }

        query.group = [{ field: 'productOffer', alias: 'i' }, { field: 'state', alias: 'i' }]

        if(this.model && this.model.settings && this.model.settings.filters ) {
          query.filter.push({type: 'eq', field: 'warehouse', value : this.model.settings.filters.warehouse})
        }

        query.filter = this.$utils.queue
          .convertObjectToFilterRevision(this.model.settings.filters)
          .map(x => {
            if (x.alias === 'i') {
              x.alias = undefined
            }

            if (x.type === 'orx' || x.type === 'andx') {
              x.conditions = x.conditions.map(c => ({ ...c, alias: undefined }))
            }

            if (x.field === 'id') {
              if (x.type === 'eq') {
                x = {type: x.type, field: 'place', value: x.value}
              } else {
                x = {type: x.type, field: 'place', values: x.values}
              }
            }

            return x
          })
      query.filter.push({type: 'notin', field: 'state', values: ['new', 'shipped', 'deleted', 'expected', 'delivered']})
        return this.$service.storageItemEntity.getAll(query, this.place && this.place.id)
          .then(({ items, totalPages, page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }

            this.items = items.map(x => ({ ...x._embedded.productOffer, ...x }))

            return { items, totalPages, page, totalItems }
          })
          .finally(() => {
            this.isLoading = false
          })
      // this.pagination = data.pagination || {}
      // const query = buildQuery(this.pagination)
      //
      // query.filter = this.$utils.queue
      //   .convertObjectToFilter(this.model.settings.filters , this.ifShop)
      //   .map(x => {
      //     if (x.alias === 'o') {
      //       x.alias = undefined
      //     }
      //
      //     if (x.type === 'orx' || x.type === 'andx') {
      //       x.conditions = x.conditions.map(c => ({ ...c, alias: undefined }))
      //     }
      //
      //     return x
      //   })
      //
      // return this.loadOrders(query)
      //   .then(({ page, totalItems }) => {
      //     this.pagination = {
      //       ...this.pagination,
      //       page,
      //       rowsNumber: totalItems
      //     }
      //   })
    },
    close () {
      this.isOpen = false
    },
    open (queue, service, type , id) {
      this.type = type
      this.model = _.cloneDeep(queue)
      this.service = service

      // http://localhost:8080/#/workstation/queues/revision/entity/749

      if(type === 'picking') {
        this.loadPickingQueueRawSettings(id)
          .then(item => {
            this.shopFilterRow = []
            if (Array.isArray(item.filters)) {
              this.shopFilterRow = item.filters.filter(e=>e.field = 'shop')
              if(this.shopFilterRow.length > 0) {
                if (this.shopFilterRow.some(e=>e.type ==='neq' || e.type === 'notin')) {
                  this.ifShop = true
                }
              }
            }
            this.onRequest()
            this.loadFields()
            this.loadParams()
            this.isOpen = true
          })
      } else if (type === 'packing') {
        this.loadAssemblingQueueRawSettings(id)
          .then(item => {
            this.shopFilterRow = []
            if (Array.isArray(item.filters)) {
              this.shopFilterRow = item.filters.filter(e=>e.field = 'shop')
              if(this.shopFilterRow.length > 0) {
                if (this.shopFilterRow.some(e=>e.type ==='neq' || e.type === 'notin')) {
                  this.ifShop = true
                }
              }
            }
            this.onRequest()
            this.loadFields()
            this.loadParams()
            this.isOpen = true
          })
      }

    },
    loadParams () {
      Object.keys(this.model.settings.filters || {})
        .forEach(key => {
          if (this.model.settings.filters[key] === undefined) {
            return
          }

          const data = {
            ...this.fieldsByKey[key],
            field: key,
            value: this.model.settings.filters[key]
          }

          if (data.type === 'tag' && data.service && data.value && data.value.length > 0 && (this.shopFilterRow[0].type === 'neq' || this.shopFilterRow[0].type === 'notin')) {
            const query = {
              per_page: data.value.length,
              page: 1,
              filter: [
                { type: 'notin', field: 'id', values: data.value }
              ]
            }

            data.service.getAll(query)
              .then(({ items }) => {
                this.fieldsByKey[key].values = items.reduce((acc, item) => {
                  acc[item.id] = item
                  return acc
                }, {})
              })
          } else if (data.type === 'tag' && data.service && data.value && data.value.length > 0) {
            const query = {
              per_page: data.value.length,
              page: 1,
              filter: [
                { type: 'in', field: 'id', values: data.value }
              ]
            }

            data.service.getAll(query)
              .then(({ items }) => {
                this.fieldsByKey[key].values = items.reduce((acc, item) => {
                  acc[item.id] = item
                  return acc
                }, {})
              })
          }
        })
    },
    save () {
      return this.service.saveFiltersNoOrder(this.model.settings, this.model.id , this.ifShop)
        .then(queue => {
          this.$emit('submit', queue)
        })
    },
    createField (data) {
      const types = {
        tag: () => {
          return this.createTagField(data)
        },
        date: () => {
          return this.createDateInput(data)
        },
        default: () => {
          return this.createInputField(data)
        },
        select: () => {
          return this.createSelectField(data)
        }
      }

      return typeof types[data.type] === 'function'
        ? types[data.type]()
        : types.default()
    },
    createInputField (data) {
      return [
        {
          type: 'input',
          inputType: data.inputType || 'text',
          value: this.model.settings.filters[data.field],
          label: this.$t(data.label),
          wrapperStyleClasses: 'col-12 q-pa-xs',
          required: !!data.required,
          onChange: value => {
            this.updateFilter(data.field, value)
          }
        }
      ]
    },
    createSelectField (data) {
      let name = []
      this.fieldsByKey[data.field].options.forEach(e=> {
        if(e.id === this.model.settings.filters[data.field]) {
          name = e.name
        }
      })

      const field = {
        type: 'select',
        label: this.$t(data.label),
        wrapperStyleClasses: 'col-12 q-pa-xs',
        field: data.field,
        value: name,
        required: !!data.required,
        customLabel: (row) => {
          return row && typeof row === 'object'
            ? row.name
            : row
        },
        onChange: (value) => {

          this.hasChange = true

          this.updateFilter(data.field, value.id)
        }
      }

      field.options = data.options

      return [field]
    },
    createTagField (data) {
      if (!Array.isArray(this.model.settings.filters[data.field])) {
        this.model.settings.filters[data.field] = [this.model.settings.filters[data.field]].filter(x => !!x)
      }

      const field = {
        type: 'tag',
        label: this.$t(data.label),
        wrapperStyleClasses: 'col-12 q-pa-xs',
        field: data.field,
        value: this.model.settings.filters[data.field],
        required: !!data.required,
        customTag: (item) => {
          if (this.fieldsByKey[data.field].values && this.fieldsByKey[data.field].values[item] && data.field === 'id') {
            const path = this.loadPath(this.fieldsByKey[data.field].values[item])
            return `${path.join(' - ')} (${this.fieldsByKey[data.field].values[item].code}*) (${this.fieldsByKey[data.field].values[item].type})`
          }
          if (this.fieldsByKey[data.field].values && this.fieldsByKey[data.field].values[item]) {
            const name = this.fieldsByKey[data.field].values[item].name || this.fieldsByKey[data.field].values[item].title
            return `${name} (${item})`
          }

          return item && typeof item === 'object'
            ? item.name || item.title || this.$t('No name')
            : item
        },
        customListItem: (item) => {
          if (item && typeof item === 'object' && data.field === 'id') {
            const path = this.loadPath(item)
            return `${path.join(' - ')} (${item.code}*) (${item.type})`
          }
          return item && typeof item === 'object'
            ? (item.name || item.title || this.$t('No name')) + ` (${item.id})`
            : item
        },
        onRemove: (val) => {
          this.updateFilter(data.field, this.model.settings.filters[data.field].filter(x => `${x}` !== `${val}`))
        },
        onAdd: (value) => {
          if (Array.isArray(value)) {
            return
          }

          this.hasChange = true
          let hasValue = false

          if (!this.model.settings.filters[data.field]) {
            this.updateFilter(data.field, [])
          }

          const values = this.model.settings.filters[data.field].filter(val => {
            if (`${val}` === `${value.id}`) {
              hasValue = true
              return false
            }

            return true
          })

          if (hasValue) {
            this.updateFilter(data.field, values)
            return
          }

          if (this.fieldsByKey[data.field].values) {
            this.fieldsByKey[data.field].values[value.id] = value
          }

          this.updateFilter(data.field, [value.id, ...this.model.settings.filters[data.field]])
        },
        btnStyleClasses: 'q-pl-sm',
        buttons: [
          {
            if: data.field === 'shop',
            id: 'switch',
            color: this.ifShop?'negative' : 'teal',
            label: this.ifShop? 'Not in' : 'In',
            size: 'lg',
            onClick: () => {
              if(this.ifShop) {
                this.ifShop = false
                this.loadFields()
                this.onRequest()
              } else {
                this.ifShop = true
                this.loadFields()
                this.onRequest()
              }
            }
          }
        ]
      }

      if (data.service) {
        field.onScroll = (search, page) => {
          const query = {
            per_page: 25,
            page,
            search
          }

          return data.service.getAll(query)
        }
      } else {
        field.options = data.options
      }

      return [field]
    },
    createDateInput (data) {
      return [
        {
          type: 'date',
          value: this.model.settings.filters[data.field].from,
          label: this.$t(data.label) + ' ' + this.$t('from'),
          wrapperStyleClasses: 'col-6 q-pa-xs',
          required: !!data.required,
          hasResetBtn: true,
          onChange: value => {
            this.model.settings.filters[data.field].from = value
            this.loadFields()
            this.onRequest()
          }
        },
        {
          type: 'date',
          value: this.model.settings.filters[data.field].to,
          label: this.$t(data.label) + ' ' + this.$t('to'),
          wrapperStyleClasses: 'col-6 q-pa-xs',
          required: !!data.required,
          hasResetBtn: true,
          onChange: value => {
            this.model.settings.filters[data.field].to = value
            this.loadFields()
            this.onRequest()
          }
        }
      ]
    },
    getFilters () {
      return Object.keys(this.fieldsByKey).map(id => ({ id, name: this.fieldsByKey[id].label }))
    },
    loadPath(place) {
      if (!place.path) {
        if (place.type === 'room') {
          return [(place.placeAlias || '')]
        }

        return [place.room, place.row, place.rack, place.shelf].map(x => x || 0)
      }

      return [...place.path, (place.placeAlias || '')]
    },
    loadFields () {
      this.fields = Object.keys(this.model.settings.filters || {})
        .reduce((acc, key) => {
          // If value is undefined it means that this field is deleted
          // If value is null it mean that value is just not set
          if (this.model.settings.filters[key] === undefined) {
            return acc
          }

          return [
            ...acc,
            ...this.createField({ ...this.fieldsByKey[key], field: key })
          ]
        }, [])
    },
    addFilter (key) {
      // If value is undefined then we must add new settings
      // Otherway we should remove settings
      if (this.model.settings.filters[key] === undefined) {
        const defaults = {
          'tag-field': [],
          date: { from: null, to: null }
        }

        return this.updateFilter(key, defaults[this.fieldsByKey[key].type] || null)
      }

      return this.updateFilter(key, undefined)
    },
    updateValues (key, value) {
      this.model = {
        ...this.model,
        settings: {
          ...this.model.settings,
          filters: {
            ...this.model.settings.filters,
            [key]: value
          }
        }
      }
    },
    updateFilter (key, value) {
      this.updateValues(key, value)
      this.loadFields()
      this.onRequest()
    }
  }
}
</script>
