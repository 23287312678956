<template>
  <q-dialog v-model="isOpen" :maximized="$q.screen.width < 700">
    <q-card style="min-width: 60vw;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
            {{ $t('Collusion') }}
        </div>

        <q-space/>

        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="hide"
        />
      </q-card-section>

      <q-card-section class="q-pt-none">
        <div class="text-subtitle1 text-center q-mb-md q-px-md">
            {{ $t('Count for warehouses') }}
        </div>
        <q-table
            style="height: 100%;"
            row-key="id"
            :rows-per-page-label="$t('Rows per page')"
            :rows="items"
            :columns="columns"
            v-model:pagination="pagination"
            :loading="isLoading"
            hide-bottom
            virtual-scroll
            binary-state-sort
        >

          <template v-slot:loading>
            <q-inner-loading
                showing
                color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
                :props="props"
                class="clickable"
            >
              <q-td
                  key="warehouse"
                  :props="props"
                  auto-width
              >
                  {{`${props.row._embedded.warehouse.name} (${props.row._embedded.warehouse.id})`}}
              </q-td>

              <q-td
                  key="count"
                  :props="props"
                  auto-width
              >
                  {{props.row.count}}
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>

      <q-card-section class="text-center justify-center">
          <div class="row">
              <editable-input-field
                  label="Scanned Count"
                  inputLabel="Scanned Count"
                  :value="newQuantity"
                  class="col-9 q-mb-sm q-ml-sm"
                  :update-function="newValue => handleNumberUpdate(newValue)"
                  :rules="[
                    (val) => /^[0-9]\d*$/.test(val)
                  ]"
              ></editable-input-field>

              <q-btn
                      color="light-blue-9"
                      text-color="white"
                      size="sm"
                      class="col-2 q-mb-sm q-ml-lg q-mr-sm"
                      :label="$t('Save Edit Count')"
                      @click="save"
              />
          </div>


      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
            color="dark"
            text-color="white"
            class="q-mr-sm"
            :label="$t('Close')"
            @click="hide"
        />

        <q-btn
            color="light-blue-9"
            text-color="white"
            :label="$t('Save')"
            @click="save"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>
<script>
import {buildQuery} from "@/apps/app/utils/query-utils";
import {mapActions} from "vuex";
import EditableInputField from "@/apps/app/components/delivery-services/EditableInputField.vue";

export default {
  name: 'RevisionCollusionModal',
  components: {
    EditableInputField
  },
  emits: ['submit'],
  data () {
    return {
      isOpen: false,
      sequence: null,
      items: [],
      isLoading : false,
      newQuantity: 0,
      pagination: {
        page: 1,
        per_page: 25,
        totalItems: 25
      },
      columns: [
        {
          label: 'Warehouse',
          name: 'warehouse',
          align: 'left'
        },
        {
          label: 'Quantity',
          name: 'count',
          align: 'left'
        }
      ]
    }
  },
  computed: {

  },
  methods: {
    ...mapActions([
      'loadStorageItems'
    ]),
    show(sequence) {
      this.sequence = sequence
      this.newQuantity = this.sequence.scannedCount
      this.onRequest()

      this.isOpen = true

    },
    handleNumberUpdate (value) {
      this.newQuantity = value
    },
    save () {
      this.$emit('submit', this.newQuantity, this.sequence)
      this.isOpen = false
    },
    onRequest(data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      if (query.search && query.search[query.search.length - 1] !== '*' && query.search[query.search.length - 2] !== ':' && !query.search.includes('%')) {
        query.search += query.search[query.search.length - 1] === ':'
          ? '*'
          : ':*'
      }
      query.filter = []
      if(this.sequence?._embedded?.place ) {
        query.filter.push({type: 'eq', field: 'place', value: this.sequence._embedded.place.id })
      }
      if (this.sequence?._embedded?.productOffer) {
        query.filter.push({type: 'eq', field: 'productOffer', value: this.sequence._embedded.productOffer.id })
      }
      query.group = [{field: 'warehouse'}]

      this.isLoading = true

      return this.loadStorageItems(query)
        .then(({ items , page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }
          this.items = items
        })
        .catch(err => {
          if (err.message === 'No warehouses found') {
            this.isNeededWarehouse = true
          }
        })
        .finally(() => {
        this.isLoading = false
      })
    },
    hide() {
      this.isOpen = false
    },
  }
}
</script>